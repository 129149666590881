import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import MainLayoutPart from '../../components/MainLayoutPart'
import Form from 'react-bootstrap/Form'
import { StyledFormControl, StyledFormLabel } from './StyledContactForm'
import StyledButton from '../../styledComponents/StyledButton'
import SectionTitle from '../../components/SectionTitle'
import { OneColSection } from '../../components/OneColSection'

const ContactForm = () => {
  const [validated, setValidated] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
      setValidated(true)
      return
    }

    setValidated(true)

    try {
      const formData = new FormData(form)
      const response = await fetch('../../../api/submit', {
        // Hier die URL ändern
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })

      if (response.ok) {
        // Redirect to success page
        window.location.href = '/contactsuccess'
        // Prevent further execution
        // return
      } else {
        console.error('Form submission failed')
        // Handle error, e.g., display an error message
      }
    } catch (error) {
      console.error('Form submission failed', error)
      // Handle error, e.g., display an error message
    }
  }

  return (
    <StaticQuery
      query={graphql`
        {
          allPrismicPage {
          nodes {
            
                data {
                  body {
                    ... on PrismicPageDataBodyKontaktformular {
                    id
                      primary {
                        submitt_button_text
                        submitted_text {
                          raw
                        }
                        form_instructions {
                          raw
                        }
                        form_title {
                          raw
                        }
                      }
                      items {
                        field_name
                        field_type
                        invalid_feedback_text
                        required
                      }
                      slice_type
                    }
                  }
                }
              }
            }
        }
      `}
      render={(data) => {
        console.log('ContactForm data', data)

/*         function findeNichtLeeresObjekt(data) {
          const body = data.prismicPage.data.body

          for (let i = 0; i < body.length; i++) {
            const objekt = body[i]
            if (Object.keys(objekt).length > 0) {
              return objekt // Gib das erste gefundene nicht-leere Objekt zurück
            }
          }

          return null // Gib null zurück, wenn kein nicht-leeres Objekt gefunden wurde
        } */
               function findeNichtLeeresObjekt(data) {
                 const nodes = data.allPrismicPage.nodes
                 // console.log('nodes', nodes)
                 for (let i = 0; i < nodes.length; i++) {
                   const body = nodes[i].data.body
                   // console.log('body', body)
                   for (let j = 0; j < body.length; j++) {
                     const objekt = body[j]
                     if (Object.keys(objekt).length > 0) {
                       return objekt // Gib das erste gefundene nicht-leere Objekt zurück
                     }
                   }
                 }
                 return null // Gib null zurück, wenn kein nicht-leeres Objekt gefunden wurde
               }


        const slice = findeNichtLeeresObjekt(data)
        // console.log('kontaktformular', kontaktformular)

        // const slice = data.prismicPage.data.body[1]
        console.log('slice', slice)
        const formTitle = slice.primary.form_title.raw
        const formInstructions = slice.primary.form_instructions.raw

        return (
          <>
            {formTitle ? (
              <SectionTitle title={formTitle} />
            ) : null}
            {formInstructions ? (
              <OneColSection content={formInstructions} />
            ) : null}
            <MainLayoutPart
              content={
                <Form
                  name="contact8"
                  method="POST"
                  data-netlify-honeypot="bot-field"
                  data-netlify="true"
                  lang="de"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                >
                  <p hidden>
                    <label>
                      Dont fill this out: <input name="bot-field" />
                    </label>
                  </p>
                  <input type="hidden" name="form-name" value="contact8" />
                  {slice.items.map((field, i) => {
                    if (field.field_type !== 'textarea') {
                      return (
                        <Form.Group key={i}>
                          <StyledFormLabel htmlFor={field.field_name}>
                            {field.field_name}{' '}
                            {field.required === true ? ' *' : null}
                          </StyledFormLabel>
                          <StyledFormControl
                            name={field.field_name}
                            required={field.required === true}
                            type={field.field_type}
                            id={field.field_name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {field.invalid_feedback_text}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )
                    } else {
                      return (
                        <Form.Group key={i}>
                          <StyledFormLabel htmlFor={field.field_name}>
                            {field.field_name}{' '}
                            {field.required === true ? ' *' : null}
                          </StyledFormLabel>
                          <StyledFormControl
                            // as="textarea"
                            name="message"
                            rows={3}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {field.invalid_feedback_text}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )
                    }
                  })}
                  <StyledButton variant="outline-danger" type="submit">
                    {slice.primary.submitt_button_text}
                  </StyledButton>
                </Form>
              }
            />
          </>
        )
      }}
    />
  )
}

export { ContactForm }
